import React from "react";
import clsx from "clsx";
import TextCircle from "@organisms/TextCircle";
import { ArticleCard } from "@molecules";
import { Container, Button, Text } from "@atoms";
import useLatestResourcesData from "@staticQueries/LatestResourcesRaQuery";

import { SITE } from "@constants";

const ArticleGrid = ({
  bgColor,
  heading,
  subheading,
  articles: _articles,
  button,
  className: _className,
}) => {
  const latestArticles = useLatestResourcesData();
  const articles = _articles?.length ? _articles : latestArticles;
  return (
    <section
      className={clsx(
        "py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
        },
        _className
      )}
    >
      <div>
        <Container className="relative">
          {heading && (
            <Text variant="h2" className={clsx("text-center text-mint")}>
              {heading}
            </Text>
          )}
          {subheading && (
            <div className="mx-auto max-w-xl">
              <Text
                variant="body"
                className={clsx("my-10 text-center text-white")}
              >
                {subheading}
              </Text>
            </div>
          )}
          <TextCircle
            name={SITE === "default" ? "watermarkInsights" : "turnstileRa"}
            position="left"
            transparent
          />
          <div className="relative flex flex-wrap justify-center gap-4">
            {articles?.map(article => (
              <ArticleCard
                key={article.uid}
                className="flex-grow md:flex-[0_1_calc(50%-.5rem)] lg:flex-[0_1_calc(33.33%-.67rem)]"
                {...article}
              />
            ))}
          </div>
          {button?.url && (
            <div className="mt-12 flex justify-center">
              <Button color="carnation-light" to={button.url}>
                {button.text || "View All"}
              </Button>
            </div>
          )}
        </Container>
      </div>
    </section>
  );
};

ArticleGrid.defaultProps = {
  bgColor: "midnight",
};

export default ArticleGrid;
