import React from "react";
import clsx from "clsx";
import { m, useTransform, useViewportScroll } from "framer-motion";
import { Icon } from "@atoms";

const TextCircle = ({ name, position, transparent }) => {
  const { scrollY } = useViewportScroll();
  const rotationSpeed = useTransform(scrollY, value => value / 8);

  return (
    <div className="relative">
      <m.div
        className={clsx(
          "absolute z-30 h-[10rem] w-[10rem] transform font-sans text-4xl font-bold uppercase tracking-wide text-white sm:h-[12rem] sm:w-[12rem] md:h-[14rem] md:w-[14rem] xl:h-[18rem] xl:w-[18rem]",
          {
            "opacity-50": transparent,
            "-bottom-6 -right-4 md:-bottom-12": position === "right",
            "-left-[4rem] -top-[6rem] md:-left-[8rem] md:-top-[6rem] xl:-left-[10rem] xl:-top-[8rem]":
              position === "left",
          }
        )}
        style={{ rotate: rotationSpeed }}
      >
        {/* todo: replace with new watermark */}
        <Icon name={name} className="h-full w-full text-white" />
      </m.div>
    </div>
  );
};

TextCircle.defaultProps = {
  name: undefined,
  transparent: false,
  position: "right",
};

export default TextCircle;
