import resolveResource from "@dataResolvers/resolveResource";
import { useStaticQuery, graphql } from "gatsby";

export const query = graphql`
  query LatestResourcesRaQuery {
    craft {
      entries(section: "resources", site: ["robActon"], limit: 3) {
        ... on Craft_resources_article_Entry {
          uid
          title
          postDate
          uid
          url
          type: typeHandle
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
        }
        ... on Craft_resources_default_Entry {
          uid
          title
          postDate
          uid
          url
          type: typeHandle
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
        }
        ... on Craft_resources_firesideChat_Entry {
          uid
          title
          postDate
          uid
          url
          type: typeHandle
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
        }
        ... on Craft_resources_webinar_Entry {
          uid
          title
          postDate
          uid
          url
          type: typeHandle
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
        }
        ... on Craft_resources_whitepaper_Entry {
          uid
          title
          postDate
          uid
          url
          type: typeHandle
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  const resources = entries.map(resolveResource);
  return resources;
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
